<template>
  <div>
    <main class="" style="background-color:#fff;height:800px;overflow-y:scroll">
        <div style="height:900px;">
           aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>
           aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>
           aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>
           aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>
           aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>
           aaa<br/>
           aaa<br/>
           aaa<br/>
           aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>aaa<br/>
        </div>
    </main>
    </div>
  </template>
  
  
  <script setup>
  import { modellist,cannytoimg,vitoken } from '../api/ajaxApi';
  import {setCookie,getCookie,delCookie} from '../utils/cookieok';
  import {Msg} from '@/utils/tools';
  import { RadioGroup, RadioGroupLabel, RadioGroupOption,Popover, PopoverButton, PopoverGroup, PopoverPanel,Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
  import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
  } from '@heroicons/vue/24/outline'
  import { useRouter } from 'vue-router'
  const router = useRouter()
  const route = router.value;
  import { ChevronDownIcon } from '@heroicons/vue/20/solid'
  import {
      ref,
      toRefs,
      onMounted, // 组件加载完成时调用
      onUpdated, // 组件更新完成时调用
      onUnmounted, // 组件卸载完成时调用
      onBeforeMount, // 组件加载前时调用
      onBeforeUpdate, // 组件更新前时调用
      onBeforeUnmount, // 组件卸载前时调用
      onActivated, // 组件激活时时调用
      onDeactivated, // 组件失活时时调用
      onErrorCaptured, // 捕获到后代组件的错误时调用
      onRenderTracked, // 状态跟踪，(组件更新时，跟踪组件里边每个值和方法的变化)
      onRenderTriggered, // 状态触发，(虚拟 DOM 重新渲染被触发时调用,此事件告诉你是什么操作触发了重新渲染，以及该操作的目标对象和键)
      onServerPrefetch, // 服务器上被渲染之前调用
  } from "vue";
  




  const navigation = [
  { name: '首页', href: '/', current: false },
  { name: '文创鞋款', href: '/txttoimg', current: false },
  { name: '以图生图', href: '/imgtoimg', current: false },
  { name: '多款合成', href: '/multitoimg', current: false },
  { name: '局部重绘', href: '/extratoimg', current: false },
  { name: '配色换料', href: '/colortoimg', current: false },
  { name: '手稿配色', href: '/cannytoimg', current: true },
  { name: '换底换面', href: '/sutoimg', current: false },
]



//图片上传处理开始

function hrefurl(url){
  router.push(url);
}

 
//图片上传处理结束
onMounted(() => {
  binstyle()
  document.title = 'AI鞋款创意设计工具';
})

function binstyle(){
  let params={
      token:getCookie("usertoken"),
  }
  vitoken(params).then(res => {
      if (res.success) {
      }
      else
      {
          router.push('/login');
      }
      })
     
}



  </script>